frappe.require('https://cdn.syncfusion.com/ej2/20.3.56/ej2-schedule/styles/material.css')
frappe.require('assets/nxiot/css/syncfusion/material.css')
frappe.require('assets/nxiot/css/syncfusion/popups/material.css')
frappe.require('assets/nxiot/css/syncfusion/navigations/material.css')
frappe.require('assets/nxiot/css/syncfusion/lists/material.css')
frappe.require('assets/nxiot/css/syncfusion/dropdowns/material.css')
frappe.require('assets/nxiot/css/syncfusion/inputs/material.css')
frappe.require('assets/nxiot/css/syncfusion/layouts/material.css')
frappe.require('assets/nxiot/css/syncfusion/richtexteditor/material.css')
frappe.require('assets/nxiot/css/syncfusion/grids/material.css')
frappe.require('@syncfusion/ej2-schedule/styles/material.css')
frappe.require('assets/nxiot/css/syncfusion/buttons/material.css');
frappe.require('assets/nxiot/css/syncfusion/calendars/material.css');